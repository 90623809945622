var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          "Duyệt công lương nhân sự: " +
          _vm.dataTransfer.fullName +
          " - T" +
          this.moment(_vm.dataTransfer.date).format("MM/yyyy"),
        visible: _vm.dialogVisible,
        width: "90%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "3vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "table-content row-data" },
        [
          _c(
            "el-table",
            {
              ref: "kidsPackageForKidsRef1",
              staticClass: "tab_child",
              staticStyle: { "border-radius": "0" },
              attrs: {
                data: _vm.responseDetailDataList,
                "highlight-current-row": "",
                "cell-style": _vm.tableRowStyleChildren,
                "header-cell-style": _vm.$funcCommon.tableHeaderColorFunction,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", align: "center", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "left",
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "left",
                  label: "Tên khoản",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " + _vm._s(scope.row.name) + "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  label: "Trạng thái",
                  align: "center",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.paid == 0
                          ? _c("span", [_vm._v("Chưa thanh toán")])
                          : scope.row.paid < scope.row.money
                          ? _c("span", [_vm._v("Thiếu")])
                          : _c("span", [_vm._v("Đủ")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Loại",
                  prop: "fnEmployeeSalary.category",
                  "min-width": "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.category == "out"
                          ? _c("p", [_vm._v("Chi")])
                          : scope.row.category == "in"
                          ? _c("p", [_vm._v("Thu")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "right",
                  label: "Giá tiền",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.discount
                          ? _c("span", [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.discountPrice
                                      )
                                    )
                                ),
                              ]),
                            ])
                          : _c("span", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrencyNew")(scope.row.price)
                                  )
                                ),
                              ]),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "calculateNumber",
                  align: "center",
                  label: "Số dự toán",
                  "min-width": "90",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Số sử dụng",
                  "min-width": "100",
                  prop: "fnEmployeeSalary.userNumber",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.approved ||
                        scope.row.locked ||
                        !_vm.checkPermission(["salary_approved_useNumber"])
                          ? _c("span", [_vm._v(_vm._s(scope.row.userNumber))])
                          : _c("el-input", {
                              staticStyle: { width: "70px" },
                              attrs: {
                                type: "number",
                                size: "small",
                                min: 0,
                                max: 10000,
                              },
                              model: {
                                value: scope.row.userNumber,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "userNumber", $$v)
                                },
                                expression: "scope.row.userNumber",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Tiền phải trả",
                  "min-width": "120",
                  align: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.discount
                          ? _c("span", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrencyNew")(
                                      (scope.row.userNumber *
                                        scope.row.discountPrice) /
                                        scope.row.number
                                    )
                                  )
                                ),
                              ]),
                            ])
                          : _c("span", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrencyNew")(
                                      (scope.row.userNumber * scope.row.price) /
                                        scope.row.number
                                    )
                                  )
                                ),
                              ]),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Tiền đã trả",
                  "min-width": "120",
                  align: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatCurrencyNew")(scope.row.paid))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "Duyệt",
                  "min-width": "65",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            disabled:
                              scope.row.showNumber == 0 ||
                              scope.row.price == 0 ||
                              scope.row.userNumber != scope.row.showNumber ||
                              scope.row.locked ||
                              scope.row.paid > 0 ||
                              !_vm.checkPermission([
                                "salary_approved_approved",
                              ]),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleApprove(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.approved,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "approved", $$v)
                            },
                            expression: "scope.row.approved",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "Khóa",
                  "min-width": "65",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _c("el-checkbox", {
                        attrs: {
                          disabled: !_vm.checkPermission([
                            "salary_approved_locked",
                          ]),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleLocked(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.locked,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "locked", $$v)
                          },
                          expression: "scope.row.locked",
                        },
                      })
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "Tác vụ",
                  width: "170",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.approved ||
                        scope.row.locked ||
                        !_vm.checkPermission(["salary_approved_useNumber"])
                          ? _c("span", [
                              scope.row.paid > 0
                                ? _c("span", [_vm._v("Không thể sửa")])
                                : scope.row.locked
                                ? _c("span", [_vm._v("Đã khóa, có thể sửa")])
                                : scope.row.approved
                                ? _c("span", [_vm._v("Đã duyệt, có thể sửa")])
                                : _vm._e(),
                            ])
                          : _c(
                              "span",
                              [
                                scope.row.showNumber ==
                                scope.row.calculateNumber
                                  ? _c("el-button", {
                                      attrs: {
                                        disabled: "",
                                        type: "primary",
                                        size: "mini",
                                        icon: "el-icon-d-arrow-right",
                                      },
                                    })
                                  : _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: "Sử dụng số dự toán",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                            icon: "el-icon-d-arrow-right",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.transferNumberOneMethod(
                                                scope.row
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                scope.row.userNumber != 0 &&
                                scope.row.showNumber == scope.row.userNumber
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled: "",
                                          size: "mini",
                                          type: "success",
                                        },
                                      },
                                      [_vm._v("Đã lưu")]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "success",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveUsedNumberOneMethod(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Lưu số")]
                                    ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm.checkPermission(["salary_approved_useNumber"])
            ? _c(
                "el-button",
                {
                  staticClass: "button-bottom",
                  attrs: { type: "success", loading: _vm.loaddingButton1 },
                  on: {
                    click: function ($event) {
                      return _vm.transferNumberManyMethod()
                    },
                  },
                },
                [_vm._v("\n      Sử dụng số dự toán\n    ")]
              )
            : _vm._e(),
          _vm.checkPermission(["salary_approved_useNumber"])
            ? _c(
                "el-button",
                {
                  staticClass: "button-bottom",
                  attrs: { type: "success", loading: _vm.loaddingButton2 },
                  on: {
                    click: function ($event) {
                      return _vm.saveUseNumberManyMethod()
                    },
                  },
                },
                [_vm._v("\n      Lưu số\n    ")]
              )
            : _vm._e(),
          _vm.checkPermission(["salary_approved_approved"]) ||
          _vm.checkPermission(["salary_approved_locked"])
            ? _c(
                "el-dropdown",
                { on: { command: _vm.handleCommandAction } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "button-bottom",
                      attrs: {
                        type: "success",
                        loading: _vm.loadingButtonAction,
                      },
                    },
                    [
                      _vm._v("\n        Tác vụ\n        "),
                      _c("i", { staticClass: "el-icon-caret-bottom" }),
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    { staticClass: "el-dropdown-menu-container" },
                    [
                      _vm.checkPermission(["salary_approved_approved"])
                        ? _c(
                            "el-dropdown-item",
                            { attrs: { command: "approved" } },
                            [_vm._v("Duyệt")]
                          )
                        : _vm._e(),
                      _vm.checkPermission(["salary_approved_approved"])
                        ? _c(
                            "el-dropdown-item",
                            { attrs: { command: "unApproved" } },
                            [_vm._v("Bỏ duyệt")]
                          )
                        : _vm._e(),
                      _vm.checkPermission(["salary_approved_locked"])
                        ? _c(
                            "el-dropdown-item",
                            { attrs: { command: "locked" } },
                            [_vm._v("Khóa")]
                          )
                        : _vm._e(),
                      _vm.checkPermission(["salary_approved_locked"])
                        ? _c(
                            "el-dropdown-item",
                            { attrs: { command: "unLocked" } },
                            [_vm._v("Bỏ khóa")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticClass: "button-bottom",
              attrs: { type: "danger" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }